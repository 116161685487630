import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { graphql, Link } from 'gatsby'
import SEO from 'components/seo'
import WhatIsComponent from 'components/whatIsComponent'
import CustomPageSectionComponent from 'components/customPageSectionComponent'
import WhatWeOfferContentSection from 'components/whatWeOfferContentSection'
import SocialLinks from 'components/socialLinks'
import ContactFormButton from 'components/contactFormButton.js'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  pageWrapper,
  cloudNativeHeadingWrapper,
  leadTextStyle,
  headingColor,
  cards,
  card,
  cardBody,
  cardFront,
  cardBack,
  hoverContainer,
  desktopImage,
  mobileImage,
  contentWrapper
} from '../styles/cloudNativeSoftwareEngineering.module.css'
import {
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Kubernetes" }) {
        edges {
          node {
            cloudNativeSoftwareEngineeringFieldGroup {
              cloudNativeSoftwareEngineeringHeader
              cloudNativeSoftwareEngineeringSubtext
              cloudNativeSoftwareEngineeringCtaText
              cloudNativeSoftwareEngineeringCtaLink
              cloudNativeWhatIsSectionImage {
                sourceUrl
              }
              cloudNativeWhatIsSectionContent {
                whatIsLeadText
                whatIsHeading
                whatIsDescription
              }
              ourApproachHeader
              ourApproachSubheader
              ourApproachDescription
              whatWeOfferSectionLeadText
              whatWeOfferSectionHeader
              whatWeOfferSectionCardContent {
                frontOfCardHeroImage {
                  sourceUrl
                }
                frontOfCardHeader
                frontOfCardSubtext
                frontOfCardImage {
                  sourceUrl
                }
                frontOfCardMobileImage {
                  sourceUrl
                }
                frontOfCardHoverText
                backOfCardHeader
                backOfCardDescription
                backOfCardCtaText
                backOfCardCtaLink
              }
              whyDeimosSectionImage {
                sourceUrl
              }
              whyDeimosSectionContent {
                whyDeimosLeadText
                whyDeimosHeading
                whyDeimosDescription
              }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Kubernetes = ({ data, pageContext }) => {
  const pageData = data.wordPress.pages.edges[0].node.cloudNativeSoftwareEngineeringFieldGroup
  const whatIsSectionImage = pageData.cloudNativeWhatIsSectionImage.sourceUrl
  const whatIsSectionContent = pageData.cloudNativeWhatIsSectionContent
  const ourApproachHeader = pageData.ourApproachHeader
  const ourApproachSubheader = pageData.ourApproachSubheader
  const ourApproachDescription = pageData.ourApproachDescription
  const whatWeOfferSectionLead = pageData.whatWeOfferSectionLeadText
  const whatWeOfferSectionHeader = pageData.whatWeOfferSectionHeader
  const whatWeOfferCardContent = pageData.whatWeOfferSectionCardContent
  const whyDeimosSectionImage = pageData.whyDeimosSectionImage.sourceUrl
  const whyDeimosSectionContent = pageData.whyDeimosSectionContent
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services`,
            'name': 'Professional Services',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Kubernetes Information'
        title='Kubernetes'
      />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Kubernetes'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={cloudNativeHeadingWrapper}>
            <h1>{pageData.cloudNativeSoftwareEngineeringHeader}</h1>
            <p>{pageData.cloudNativeSoftwareEngineeringSubtext}</p>
            <ContactFormButton ctaText={pageData.cloudNativeSoftwareEngineeringCtaText} />
          </div>
        </div>
        <WhatIsComponent
          backgroundColor={'var(--formFields)'}
          image={whatIsSectionImage}
          items={whatIsSectionContent}
          render={(item, index) => {
            const { whatIsLeadText, whatIsHeading, whatIsDescription } = item
            return (
              <div key={index.toString()}>
                <span className={leadTextStyle}>{whatIsLeadText}</span>
                <h2 className={headingColor}>{whatIsHeading}</h2>
                <span>{parse(whatIsDescription)}</span>
              </div>
            )
          }}
        />
        <CustomPageSectionComponent
          items={ourApproachDescription}
          subtitle={ourApproachSubheader}
          title={ourApproachHeader}
        />
        <WhatWeOfferContentSection
          backgroundColor={'var(--formFields)'}
          items={whatWeOfferCardContent}
          leadTitle={whatWeOfferSectionLead}
          render={(item, index) => {
            const {
              frontOfCardHeroImage,
              frontOfCardHeader,
              frontOfCardSubtext,
              frontOfCardImage,
              frontOfCardMobileImage,
              frontOfCardHoverText,
              backOfCardHeader,
              backOfCardDescription,
              backOfCardCtaText,
              backOfCardCtaLink
            } = item
            return (
              <div
                className={cards}
                key={index.toString()}>
                <div className={card}>
                  <div className={cardBody}>
                    <div className={cardFront}>
                      <img src={frontOfCardHeroImage.sourceUrl} />
                      <h4>{frontOfCardHeader}</h4>
                      <span>{frontOfCardSubtext}</span>
                      <div className={hoverContainer}>
                        <img
                          className={desktopImage}
                          src={frontOfCardImage.sourceUrl} />
                        <img
                          className={mobileImage}
                          src={frontOfCardMobileImage.sourceUrl} />
                        <Link to={backOfCardCtaLink}>
                          <p>{frontOfCardHoverText}</p>
                        </Link>
                      </div>
                    </div>
                    <Link
                      className={cardBack}
                      to={backOfCardCtaLink}>
                      <h4>{backOfCardHeader}</h4>
                      {parse(backOfCardDescription)}
                      <span>{backOfCardCtaText}</span>
                    </Link>
                  </div>
                </div>
              </div>
            )
          }}
          title={whatWeOfferSectionHeader}
        />
        <WhatIsComponent
          backgroundColor={'var(--textColor)'}
          image={whyDeimosSectionImage}
          items={whyDeimosSectionContent}
          render={(item, index) => {
            const { whyDeimosLeadText, whyDeimosHeading, whyDeimosDescription } = item
            return (
              <div
                className={contentWrapper}
                key={index.toString()}>
                <span className={leadTextStyle}>{whyDeimosLeadText}</span>
                <h2 className={headingColor}>{whyDeimosHeading}</h2>
                <span>{parse(whyDeimosDescription)}</span>
              </div>
            )
          }}
        />
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

Kubernetes.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Kubernetes
